<template>
  <div class="statistic-filter">
    <HomeworkStatusFilter
      v-show="showStatusFilter"
      :clearTrigger="clearTrigger"
      :isDisabled="isDisabled"
      @updateFilter="handleFilterUpdate"
    />
    <DateDropdown
      v-show="showDateFilter"
      :title="$t('label.dateOfSend')"
      :subTitle="$t('supportText.chooseSendDateOrDateRange')"
      :clearTrigger="clearTrigger"
      :isDisabled="isDisabled"
      @updateFilter="handleFilterUpdate"
    />
    <HomeworkSortDropdown
      v-show="showSort"
      :clearTrigger="clearTrigger"
      :isDisabled="isDisabled"
      @updateFilter="handleFilterUpdate"
    />
    <ClearFilterButton @click="clearAllFilters" v-if="filtersIsDirty" />
  </div>
</template>
<script>
import DateDropdown from './Filters/DateDropdown.vue';
import HomeworkStatusFilter from './Filters/Homework/HomeworkStatusFilter.vue';
import HomeworkSortDropdown from './Filters/Homework/HomeworkSortDropdown.vue';
import ClearFilterButton from './Buttons/ClearFilterButton.vue';

export default {
  name: 'StatisticFilter',
  components: {
    DateDropdown,
    HomeworkStatusFilter,
    HomeworkSortDropdown,
    ClearFilterButton,
  },
  props: {
    showStatusFilter: {
      type: Boolean,
      default: false,
    },
    showDateFilter: {
      type: Boolean,
      default: false,
    },
    showSort: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentFilter: { selectedSort: 'emailAsc' },
    clearTrigger: 0,
  }),
  computed: {
    filtersIsDirty() {
      return (
        this.currentFilter?.statusFilter?.length
        || this.currentFilter?.dateRange?.startDate
        || this.currentFilter?.dateRange?.endDate
        || this.currentFilter.selectedSort !== 'emailAsc'
      );
    },
  },
  methods: {
    handleFilterUpdate(e) {
      this.currentFilter = { ...this.currentFilter, ...e };
      this.updateFilter();
    },
    updateFilter() {
      const filterPayload = {};

      if (this.currentFilter?.statusFilter?.length) {
        filterPayload.statusFilter = this.currentFilter.statusFilter;
      }

      if (this.currentFilter?.dateRange?.startDate && this.currentFilter?.dateRange?.endDate) {
        filterPayload.dateRange = this.currentFilter.dateRange;
      }

      filterPayload.selectedSort = this.currentFilter.selectedSort;

      this.$emit('filterChange', filterPayload);
    },
    clearAllFilters() {
      this.clearTrigger += 1;
    },
  },
  mounted() {
    this.updateFilter();
  },
  watch: {
    showStatusFilter() {
      this.clearAllFilters();
    },
    showDateFilter() {
      this.clearAllFilters();
    },
    showSort() {
      this.clearAllFilters();
    },
  },
};
</script>

<template>
  <div class="homework-card">
    <div class="homework-card__answer" v-if="taskVariant">
      <div class="left">
        <div class="name">{{ homework.userFullName }}</div>
        <div class="email">{{ homework.email }}</div>
      </div>
      <div class="right">
        <p>{{ formatDateCreation(homework.updated_at) }}</p>
        <p v-if="showAttempts">{{ $t('label.attempt') }} {{ attemptCounter }}</p>
        <ProgressStatus iconPosition="left" :status="computedStatus" />
        <div class="homework-card__info-bottom">
          <ArrowUp :class="{ active: !visible }"  @click="visible = !visible" />
        </div>
      </div>
    </div>
    <div class="homework-card__info" v-else>
      <div class="homework-card__info-top">
        <div class="homework-card__author">
          <p>{{ homework.userFullName }}</p>
          <p>{{ homework.email }}</p>
        </div>
        <div class="homework-card__status status">
          <div v-if="showAttempts" class="status__attempts">
            <p>{{ $t('label.attempt') }} {{ attemptCounter }}</p>
          </div>
          <ProgressStatus iconPosition="left" :status="computedStatus" />
        </div>
      </div>
      <div class="homework-card__info-middle">
        <div class="homework-card__course-title">
          <p>
            {{ $t('header.course') }}: <span>{{ homework.courseName }}</span>
          </p>
        </div>
        <div class="homework-card__task-title">
          <p>
            {{ $t('label.task') }}: <span>{{ homework.taskTitle }}</span>
          </p>
        </div>
      </div>
      <div class="homework-card__info-bottom">
        <ArrowUp :class="{ active: !visible }"  @click="visible = !visible" />
      </div>
    </div>

    <b-collapse id="collapse-4" class="homework-card__answer-block" v-model="visible">
      <TaskChat
        :homeworkId="homework.chatId"
        :isVisible="visible"
        :max_score="homework.max_score"
        chatType="teacher"
      />
    </b-collapse>
  </div>
</template>

<script>
import TaskChat from '@/components/TaskChat/TaskChat.vue';
import ProgressStatus from '@/components/ProgressStatus.vue';
import ArrowUp from '../Icons/ArrowUp.vue';
import { ActivityStatuses } from '../../utils/statusConstants';

export default {
  name: 'HomeworkCard',
  components: { TaskChat, ProgressStatus, ArrowUp },
  props: {
    homework: {
      type: Object,
      required: true,
    },
    taskVariant: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  created() {
    if (this.$route.query?.homeworkId) {
      this.visible = this.$route.query.homeworkId === this.homework.chatId;
    }
  },
  computed: {
    computedStatus() {
      if (this.homework?.status === ActivityStatuses.done) {
        return {
          value: 'done',
          label: 'label.accepted',
        };
      }
      if (this.homework?.status === ActivityStatuses.rejected) {
        return {
          value: 'rejected',
          label: 'label.rejected',
        };
      }
      return {
        value: 'not-started',
        label: 'label.onTheCheck',
      };
    },
    attemptCounter() {
      const userAttempts = (this.homework.usedAttempts || 0) + 1;
      const { attempts } = this.homework;
      if (userAttempts > attempts) {
        return `${attempts}/${attempts}`;
      }
      return `${userAttempts}/${attempts}`;
    },
    showAttempts() {
      return this.homework.attempts;
    },
  },
  methods: {
    formatDateCreation(date) {
      const timeToSet = new Date(date);
      return new Intl.DateTimeFormat(this.$i18n?.locale === 'en' ? 'en' : 'ru', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(timeToSet);
    },
  },
};
</script>

import isValid from 'date-fns/is_valid';
import isWithinRange from 'date-fns/is_within_range';
import { ActivityStatuses } from '../utils/statusConstants';

export default {
  methods: {
    sortHomeworks(users, sortType) {
      return users.sort((a, b) => {
        if (sortType === 'emailAsc') {
          return a.email.localeCompare(b.email);
        }
        if (sortType === 'emailDesc') {
          return b.email.localeCompare(a.email);
        }
        if (sortType === 'userAsc') {
          return a.userFullName.localeCompare(b.userFullName);
        }
        if (sortType === 'userDesc') {
          return b.userFullName.localeCompare(a.userFullName);
        }
        if (sortType === 'taskAsc') {
          return a.taskTitle.localeCompare(b.taskTitle);
        }
        if (sortType === 'taskDesc') {
          return b.taskTitle.localeCompare(a.taskTitle);
        }
        if (sortType === 'dateTaskAsc') {
          if (!a.updated_at || !isValid(new Date(a.updated_at))) return 1;
          if (!b.updated_at || !isValid(new Date(b.updated_at))) return -1;
          return new Date(a.updated_at) - new Date(b.updated_at);
        }
        if (sortType === 'dateTaskDesc') {
          return new Date(b.updated_at) - new Date(a.updated_at);
        }
        return 0;
      });
    },
    checkStatusFilterMatch(status, filterList) {
      if (filterList.includes('notStarted') && status === ActivityStatuses.doing) return true;
      if (filterList.includes('rejected') && status === ActivityStatuses.rejected) return true;
      if (
        filterList.includes('done')
        && (status === ActivityStatuses.done)
      ) return true;
      return false;
    },
    checkDateRangeFilterMatch(date, dateRange) {
      return isWithinRange(
        new Date(date).setHours(0, 0, 0, 0),
        new Date(dateRange.startDate).setHours(0, 0, 0, 0),
        new Date(dateRange.endDate).setHours(0, 0, 0, 0),
      );
    },
  },
};

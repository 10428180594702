<template>
  <div
    class="download-answers-button"
    :class="{ disabled: isDisabled }"
    @click="downloadTaskAnswers"
  >
    {{ $t('label.downloadAnswers') }}
    <DownloadIcon />
  </div>
</template>
<script>
import DownloadIcon from '@/components/Icons/DownloadIcon.vue';
import ActivityService from '@/services/activity.service';
import errorToastMixin from '@/mixins/errorToast.mixin';

export default {
  name: 'DownloadAnswersButton',
  components: {
    DownloadIcon,
  },
  mixins: [errorToastMixin],
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: String,
      default: '',
    },
  },
  methods: {
    async downloadTaskAnswers() {
      if (this.isDisabled || !this.taskId) return;
      await ActivityService.getTaskAnswersFile([this.taskId], this.$root.$i18n.locale)
        .then((response) => {
          const blob = response.data;
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'answers.xlsx');
          } else {
            const downloadLink = window.document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(
              new Blob([blob], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              }),
            );
            downloadLink.download = 'answers.xlsx';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        })
        .catch(this.errorToast);
    },
  },
};
</script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('click')">
    <path
      d="M14 11L8 5L2 11"
      stroke="#F2F2F2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'ArrowUp',
};
</script>

<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
    :disabled="isDisabled"
  >
    <template #button-content>
      <button class="filter-button">
        <button class="filter-clear" v-if="currentSort !== 'emailAsc'" @click.stop="clearFilter">
          <ClearFilterIcon />
        </button>
        {{$t('label.sort')}}: {{ getFilterLabels() }}
        <ArrowDownYellow />
      </button>
    </template>

    <b-dropdown-form>
      <b-form-group :label="`${$t('label.users')}:`" @submit.stop.prevent>
        <div class="custom-control__container">
          <b-form-radio v-model="currentSort" value="userAsc" @change="updateFilter"
            ><span>{{$t('label.A-Z')}}</span></b-form-radio
          >
        </div>
        <div class="custom-control__container">
          <b-form-radio v-model="currentSort" value="userDesc" @change="updateFilter"
            ><span>{{$t('label.Z-A')}}</span></b-form-radio
          >
        </div>
      </b-form-group>
      <b-form-group label="E-mail:" @submit.stop.prevent>
        <div class="custom-control__container">
          <b-form-radio v-model="currentSort" value="emailAsc" @change="updateFilter"
            ><span>A - Z</span></b-form-radio
          >
        </div>
        <div class="custom-control__container">
          <b-form-radio v-model="currentSort" value="emailDesc" @change="updateFilter"
            ><span>Z - A</span></b-form-radio
          >
        </div>
      </b-form-group>
      <b-form-group :label="$t('label.taskName')" @submit.stop.prevent>
        <div class="custom-control__container">
          <b-form-radio v-model="currentSort" value="taskAsc" @change="updateFilter"
            ><span>{{$t('label.A-Z')}}</span></b-form-radio
          >
        </div>
        <div class="custom-control__container">
          <b-form-radio v-model="currentSort" value="taskDesc" @change="updateFilter"
            ><span>{{$t('label.Z-A')}}</span></b-form-radio
          >
        </div>
      </b-form-group>
      <b-form-group :label="`${$t('label.dateOfSendShort')}:`" @submit.stop.prevent>
        <div class="custom-control__container">
          <b-form-radio v-model="currentSort" value="dateTaskDesc" @change="updateFilter"
            ><span>{{ $t('label.firstNew') }}</span></b-form-radio
          >
        </div>
        <div class="custom-control__container">
          <b-form-radio v-model="currentSort" value="dateTaskAsc" @change="updateFilter"
            ><span>{{ $t('label.firstOld') }}</span></b-form-radio
          >
        </div>
      </b-form-group>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import ClearFilterIcon from '@/components/Icons/ClearFilterIcon.vue';
import ArrowDownYellow from '@/components/Icons/ArrowDownYellow.vue';

export default {
  name: 'HomeworkSortDropdown',
  components: { ArrowDownYellow, ClearFilterIcon },
  props: {
    clearTrigger: {
      type: Number,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentSort: 'emailAsc',
  }),
  methods: {
    getFilterLabels() {
      if (this.currentSort === 'emailAsc') return '';
      return this.$t(`sortings.${this.currentSort}`);
    },
    clearFilter() {
      this.currentSort = 'emailAsc';
      this.updateFilter();
    },
    updateFilter() {
      this.$emit('updateFilter', { selectedSort: this.currentSort });
    },
  },
  watch: {
    clearTrigger() {
      if (this.currentSort !== 'emailAsc') {
        this.clearFilter();
      }
    },
  },
};
</script>

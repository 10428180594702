<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
    :disabled="isDisabled"
  >
    <template #button-content>
      <button class="filter-button">
        <button
          class="filter-clear"
          v-if="notStarted || rejected || done"
          @click.stop="clearFilter"
        >
          <ClearFilterIcon />
        </button>
        {{ $t('label.status') }}: {{ getFilterLabels() }}
        <ArrowDownYellow />
      </button>
    </template>

    <b-dropdown-form>
      <div class="custom-control__container">
        <b-form-checkbox v-model="notStarted" @change="updateFilter"
          ><span> {{ $t('label.underReview') }}</span
          ><ProgressNotStarted
        /></b-form-checkbox>
      </div>
      <div class="custom-control__container">
        <b-form-checkbox v-model="rejected" @change="updateFilter"
          ><span> {{ $t('label.rejected') }}</span
          ><ProgressRejected
        /></b-form-checkbox>
      </div>
      <div class="custom-control__container">
        <b-form-checkbox v-model="done" @change="updateFilter"
          ><span> {{ $t('label.accepted') }}</span
          ><ProgressDone
        /></b-form-checkbox>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import ProgressRejected from '@/components/Icons/ProgressRejected.vue';
import ProgressDone from '@/components/Icons/ProgressDone.vue';
import ProgressNotStarted from '@/components/Icons/ProgressNotStarted.vue';
import ClearFilterIcon from '@/components/Icons/ClearFilterIcon.vue';
import ArrowDownYellow from '@/components/Icons/ArrowDownYellow.vue';

const statusFilters = {
  notStarted: { title: 'label.underReview', value: 'notStarted' },
  rejected: { title: 'label.rejected', value: 'rejected' },
  done: { title: 'label.accepted', value: 'done' },
  notSelected: { title: 'label.all', value: 'notSelected' },
};
export default {
  name: 'HomeworkStatusFilter',
  components: {
    ArrowDownYellow,
    ClearFilterIcon,
    ProgressRejected,
    ProgressDone,
    ProgressNotStarted,
  },
  props: {
    clearTrigger: {
      type: Number,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    notStarted: false,
    rejected: false,
    done: false,
  }),
  methods: {
    getFilterLabels() {
      if (
        (this.notStarted && this.rejected && this.done)
        || (!this.notStarted && !this.rejected && !this.done)
      ) {
        return this.$t(statusFilters.notSelected.title);
      }
      let label = '';
      if (this.notStarted) label += `${this.$t(statusFilters.notStarted.title)}, `;
      if (this.rejected) label += `${this.$t(statusFilters.rejected.title)}, `;
      if (this.done) label += `${this.$t(statusFilters.done.title)}, `;
      return label;
    },
    clearFilter() {
      this.notStarted = false;
      this.rejected = false;
      this.done = false;
      this.updateFilter();
    },
    updateFilter() {
      const filters = [];
      if (this.notStarted) filters.push('notStarted');
      if (this.rejected) filters.push('rejected');
      if (this.done) filters.push('done');
      this.$emit('updateFilter', { statusFilter: filters });
    },
  },
  watch: {
    clearTrigger() {
      if (this.notStarted || this.rejected || this.done) {
        this.clearFilter();
      }
    },
  },
};
</script>

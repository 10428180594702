<template>
  <div class="manage-students-page check-homeworks-page">
    <div key="content" class="manage-students-page__wrapper">
      <div class="manage-students-page__back-button container-fluid">
        <BackButton
          class="navigation-back"
          :label="backButtonLabel"
          :accentLabel="currentTask ? currentTask.courseName : ''"
          :routeToPush="routeToReturn"
        />
      </div>
      <div class="manage-students-page__info-box info-box container-fluid">
        <div class="info-box__title">
          {{ $t('header.checkingHomework') }}
          <span v-if="currentTaskTitle">&laquo;{{ currentTaskTitle }}&raquo;</span>
        </div>
      </div>
      <div class="container-fluid">
        <div class="manage-students-page__content">
          <div class="check-homeworks">
            <div class="check-homeworks__filter-items" v-if="!isShown(0)">
              <div
                class="filter-item"
                v-for="(filter, index) in filters"
                :key="filter"
                :class="{
                  'filter-item--active': activeFilterIndex === index,
                }"
                @click="choseFilter(index)"
              >
                <div v-if="isShown(index)">
                  {{ $t(filter) }}
                </div>
              </div>
            </div>
            <transition name="component-fade" mode="out-in">
              <div v-if="loading" key="loader" class="f-center h-100 flex-grow-1 mt-auto">
                <div>
                  <Loader />
                </div>
              </div>
              <div v-else class="check-homeworks__list-of-homeworks">
                <div class="list-header" v-if="!isShown(0)">
                  <DownloadAnswersButton
                    :taskId="currentTask ? currentTask.taskId : ''"
                    :isDisabled="!isCurrentTaskAnswers"
                  />
                  <HomeworksFilter
                    :showStatusFilter="activeFilterIndex !== 1"
                    showDateFilter
                    showSort
                    :isDisabled="!isCurrentTaskAnswers"
                    @filterChange="handleFilterChange"
                  />
                </div>
                <HomeworkCard
                  v-for="homework in currentPageHomeworks"
                  :key="homework.chatId"
                  :homework="homework"
                  :taskVariant="pageMode === 'singleTask'"
                />
                <TablePagination
                  v-show="filteredHomeworks.length > 20"
                  :length="filteredHomeworks.length"
                  @goToPage="goToPage"
                  @setPerPage="setItemsPerPage"
                />
                <span class="empty-state" v-if="!filteredHomeworks.length">{{
                  $t('label.noAnswersYet')
                }}</span>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BackButton from '@/components/Buttons/BackButton.vue';
import HomeworkCard from '@/components/Cards/HomeworkCard.vue';
import Loader from '@/components/Loader/Loader.vue';
import paginationMixin from '@/mixins/pagination.mixin';
import homeworkFilterMixin from '@/mixins/homeworkFilter.mixin';
import TablePagination from '../../components/TablePagination.vue';
import HomeworksFilter from '../../components/HomeworksFilter.vue';
import DownloadAnswersButton from '../../components/Buttons/DownloadAnswersButton.vue';
import { ActivityStatuses } from '../../utils/statusConstants';

export default {
  name: 'CheckHomeworksPage',
  components: {
    Loader,
    HomeworkCard,
    BackButton,
    TablePagination,
    HomeworksFilter,
    DownloadAnswersButton,
  },
  mixins: [paginationMixin, homeworkFilterMixin],
  data() {
    return {
      filters: ['links.chosenAnswer', 'links.requireCheck', 'label.checked', 'links.allAnswers'],
      activeFilterIndex: 1,
      loading: true,
      currentFilter: null,
      pageMode: '',
      currentTask: null,
    };
  },
  async created() {
    if (this.$route.query?.homeworkId) {
      this.pageMode = 'singleChat';
      this.activeFilterIndex = 0;
    }
    if (this.$route.query?.taskId) {
      this.pageMode = 'singleTask';
    }
    if (!this.pageMode) {
      this.$router.replace('/manage-homeworks-table');
    }
    this.loading = true;
    await this.fetchChats();
    this.loading = false;
  },
  computed: {
    ...mapGetters({ chats: 'chats/getChatList' }),
    currentPageHomeworks() {
      if (+this.filteredHomeworks.length < 20) {
        return this.filteredHomeworks;
      }
      return this.filteredHomeworks.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    },
    filteredHomeworks() {
      if (this.loading) return;
      if (this.$route.query?.homeworkId) {
        return this.homeworks.filter((t) => t.chatId === this.$route.query?.homeworkId);
      }
      let filteredHomeworks = [];
      if (this.activeFilterIndex === 1) {
        filteredHomeworks = this.homeworks.filter((t) => t.status === ActivityStatuses.doing);
      } else if (this.activeFilterIndex === 2) {
        filteredHomeworks = this.homeworks.filter(
          (t) => t.status === ActivityStatuses.rejected || t.status === ActivityStatuses.done,
        );
      } else {
        filteredHomeworks = this.homeworks;
      }

      if (this.$route.query?.taskId) {
        filteredHomeworks = filteredHomeworks.filter((t) => t.taskId === this.$route.query?.taskId);
        if (filteredHomeworks?.length) {
          // eslint-disable-next-line  vue/no-side-effects-in-computed-properties
          this.currentTask = {
            courseName: filteredHomeworks[0].courseName,
            courseId: filteredHomeworks[0].courseId,
            programId: filteredHomeworks[0].programId,
            taskId: filteredHomeworks[0].taskId,
            TaskTitle: filteredHomeworks[0].taskTitle,
          };
        }
      }

      if (!this.currentFilter) return filteredHomeworks;

      if (this.currentFilter?.statusFilter) {
        // eslint-disable-next-line  max-len
        filteredHomeworks = filteredHomeworks.filter((item) => this.checkStatusFilterMatch(item.status, this.currentFilter.statusFilter));
      }

      if (this.currentFilter?.dateRange) {
        // eslint-disable-next-line max-len
        filteredHomeworks = filteredHomeworks.filter((item) => this.checkDateRangeFilterMatch(item.updated_at, this.currentFilter.dateRange));
      }

      if (this.currentFilter?.selectedSort) {
        filteredHomeworks = this.sortHomeworks(filteredHomeworks, this.currentFilter.selectedSort);
      }
      return filteredHomeworks;
    },
    homeworks() {
      const userChats = [];
      this.chats.forEach((value) => {
        const homework = {};
        homework.courseName = value.Course.name;
        homework.courseId = value.Course._id;
        homework.programId = value.ProgramID;
        homework.chatId = value.TaskChatID;
        homework.taskTitle = value.Task.name;
        homework.taskId = value.Task._id;
        homework.userFullName = `${value.User.firstName.trim()} ${value.User.lastName.trim()}`;
        homework.email = value.User.username;
        homework.status = value.Statistics.status;
        homework.updated_at = value.LastMessageSentAt;
        if (value.Task.meta?.attempts) {
          homework.attempts = value.Task.meta.attempts;
        }
        if (value.Statistics.meta) {
          homework.usedAttempts = value.Statistics.meta[0].Value;
        }
        homework.max_score = value.Task.max_score;
        userChats.push(homework);
      });
      return userChats;
    },
    routeToReturn() {
      if (this.pageMode === 'singleChat') return '/manage-homeworks-table';
      if (this.$route.params?.path) return this.$route.params.path;
      if (this.currentTask) return `/${this.currentTask.programId}/${this.currentTask.courseId}/content`;
      return '/programs';
    },
    backButtonLabel() {
      if (this.pageMode === 'singleChat') return this.$t('buttonLabels.backToList');
      if (this.currentTask || this.$route.params?.path) return this.$t('buttonLabels.backToCourseContent');
      return this.$t('label.backToPrograms');
    },
    isCurrentTaskAnswers() {
      return !!this.homeworks.filter((t) => t.taskId === this.$route.query?.taskId)?.length;
    },
    currentTaskTitle() {
      if (this.pageMode === 'singleChat' && this.filteredHomeworks?.length) {
        return this.filteredHomeworks[0].taskTitle;
      }
      if (this.currentTask) {
        return this.currentTask.TaskTitle;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('chats', ['fetchChats']),
    choseFilter(index) {
      this.activeFilterIndex = index;
    },
    isShown(index) {
      if (index === 0) {
        return this.activeFilterIndex === 0;
      }
      return true;
    },
    handleFilterChange(e) {
      this.currentFilter = e;
    },
  },
};
</script>
